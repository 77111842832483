import classNames from "classnames";
import React from "react";
import { ActionButton } from "./action-button";
import "./cta-footer.scss";
import { MailingList } from "./mailing-list";
import { TopWave } from "./top-wave";

interface Props {
  noBg?: boolean;

  prefix?: string;
  bold?: string;
  suffix?: string;
  subject?: string;

  description1?: string;
  description2?: string;

  linkA?: string;
  linkB?: string;
}

export const CtaFooter = ({
  noBg,
  prefix,
  bold,
  suffix,
  subject,
  description1,
  description2,
  linkA,
  linkB,
}: Props) => {
  return (
    <div className="cta-footer--main">
      {!noBg && <TopWave type="dip" />}
      <div
        className={classNames("cta-footer--content", {
          "cta-footer--content--noBG": noBg,
        })}
      >
        <p className="cta--subtitle">
          {prefix || "Ready to"}
          <span> {bold || "Boost"}</span> {suffix || "your"} <br />
          {subject || "Video Workflow?"}
        </p>
        <p className="cta--subtitle-secondary">
          {description1 || "Contact us for custom solution to"} <br />{" "}
          {description2 || `fit your team's unique needs.`}
        </p>
        <div className="cta-footer--item">
          <ActionButton
            text="Reach out today!"
            variant="solid"
            colorScheme="primary"
            size="md"
            href={linkA || "contact"}
          />
          <ActionButton
            text="How We Can Help"
            variant="outline"
            colorScheme="primary"
            size="md"
            href={linkB || "landings/boost-video"}
          />
        </div>
      </div>
    </div>
  );
};
